import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  ErrorUnexpected,
  OperationSuccessfully,
} from "../../../../const/Messages";
import * as DraNextIndexIgnoredService from "../../../../service/DraNextIndexIgnoredService";

/**
  @author Guilherme Almeida
  @date 08/01/2025
 */
export default function DraNextIndexIgnored() {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refresh();
  }, []); // eslint-disable-line

  function refresh() {
    DraNextIndexIgnoredService.findAll()
      .then((data) => {
        setList(data);
      })
      .catch(() => {
        toast.error(ErrorUnexpected);
      })
      .finally(() => setIsLoading(false));
  }

  function handleSave(data) {
    setIsLoading(true);

    return DraNextIndexIgnoredService.save(data)
      .then(() => {
        toast.success(OperationSuccessfully);
        refresh();
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          toast.error(ErrorUnexpected);
        } else if (error.response.data.title) {
          toast.error(error.response.data.title);
        } else {
          toast.error(ErrorUnexpected);
        }

        return Promise.reject();
      })
      .finally(() => setIsLoading(false));
  }

  function handleDelete(id) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        DraNextIndexIgnoredService.deleteById(id)
          .then(() => {
            DraNextIndexIgnoredService.findAll()
              .then((list) => {
                setList(list);
                toast.success(OperationSuccessfully);
              })
              .catch(() => {
                toast.error(ErrorUnexpected);
              });
          })
          .catch(() => {
            toast.error(ErrorUnexpected);
          });
      }
    });
  }

  const columns = [
    {
      title: "Next Index",
      field: "nextIndex",
      type: "numeric",
      cellStyle: {
        textAlign: "left", // For aligning text to the left
      },
      headerStyle: {
        textAlign: "left", // Align header to the left as well
        direction: "rtl",
      },
      validate: (rowData) =>
        rowData.nextIndex ? true : "Next Index is required",
    },
  ];

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className=" d-flex justify-content-between align-items-center">
            <h5>Ignored</h5>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={list}
              isLoading={isLoading}
              editable={{
                onRowAdd: (newData) => handleSave(newData),
              }}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              actions={[
                {
                  icon: () => <FiTrash2 size={16} color="#007bff" />,
                  tooltip: "DELETE",
                  onClick: (event, rowData) => handleDelete(rowData.id),
                },
              ]}
              options={{
                addRowPosition: "first", // A linha de adição aparece no início
                rowStyle: {
                  fontSize: 13,
                  "& .MuiSelect-root": {
                    width: "100%", // Ajuste o tamanho do Select
                  },
                },
                pageSize: 5,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: false,
                exportAllData: false,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
