import React from "react";
import { Col, Row } from "react-bootstrap";
import DraNextIndexCustom from "../../../../../../components/Template/Dra/NextIndex/DraNextIndexCustom";
import DraNextIndexDefault from "../../../../../../components/Template/Dra/NextIndex/DraNextIndexDefault";
import DraNextIndexIgnored from "../../../../../../components/Template/Dra/NextIndex/DraNextIndexIgnored";

/**
  @author Guilherme Almeida
  @date 08/01/2025
 */
export default function DraNextIndex() {
  return (
    <Row>
      <Col md={12}>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between align-items-center">
            <h5>Next Index - DRA Huawei</h5>
          </div>
          <div className="ibox-content">
            <Row>
              <Col md={4}>
                <DraNextIndexIgnored />
              </Col>
              <Col md={8}>
                <DraNextIndexCustom />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <DraNextIndexDefault />
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
}
