import React, { useContext, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ADMIN, VERTIS } from "../const/Roles";
import { Context } from "../context/Context";
import PrivateRoute from "./PrivateRoute";

import ForgotPasswordChangePassword from "../pages/Auth/ForgotPassword2/ChangePassword";
import ForgotPasswordGenerateToken from "../pages/Auth/ForgotPassword2/GenerateToken";
import ForgotPasswordGenerateTokenSuccess from "../pages/Auth/ForgotPassword2/GenerateTokenSuccess";
import Login from "../pages/Auth/Login2/Login";

import AlertList from "../pages/Main/Alert/AlertList";
import Home from "../pages/Main/Home/Home";
import ProfileChangePassword from "../pages/Main/Profile/ChangePassword";
import Profile from "../pages/Main/Profile/Profile";
import UserList from "../pages/Main/User/List/UserList";
import UserRegister from "../pages/Main/User/Register/UserRegister";

import ReleaseNotes from "../pages/Main/ReleaseNotes/ReleaseNotes";

import SettingsMenu from "../pages/Main/Configuration/SettingsMenu";

import ElementList from "../pages/Main/Configuration/Element/List/ElementList";
import ElementSummaryList from "../pages/Main/Configuration/Element/List/ElementSummaryList";
import ElementRegister from "../pages/Main/Configuration/Element/register/ElementRegister";
import GlobalTitleList from "../pages/Main/Configuration/GlobalTitle/List/GlobalTitleList";
import HubList from "../pages/Main/Configuration/Hub/List/HubList";
import HubRegister from "../pages/Main/Configuration/Hub/Register/HubRegister";
import ImsiChangeRuleList from "../pages/Main/Configuration/ImsiChangeRule/List/ImsiChangeRuleList";
import ImsiChangeRuleRegister from "../pages/Main/Configuration/ImsiChangeRule/Register/ImsiChangeRuleRegister";
import OrganisationList from "../pages/Main/Configuration/Organisation/List/OrganisationList";
import OrganisationRegister from "../pages/Main/Configuration/Organisation/Register/OrganisationRegister";
import RealmsList from "../pages/Main/Configuration/Realms/List/RealmsList";
import RealmsRegister from "../pages/Main/Configuration/Realms/Register/RealmsRegister";
import RoamingPartnersList from "../pages/Main/Configuration/RoamingPartners/List/RoamingPartnersList";
import RoamingPartnersRegister from "../pages/Main/Configuration/RoamingPartners/Register/RoamingPartnersRegister";
import RoamingPartnersUploadList from "../pages/Main/Configuration/RoamingPartners/Upload/List/RoamingPartnersUploadList";
import TadigAssociationRegister from "../pages/Main/Configuration/Tadig/Association/Register/TadigAssociationRegister";
import TadigAssociation from "../pages/Main/Configuration/Tadig/Association/TadigAssociation";
import TadigList from "../pages/Main/Configuration/Tadig/List/TadigList";
import TadigRegister from "../pages/Main/Configuration/Tadig/Register/TadigRegister";
import TemplateHuaweiDraDefault from "../pages/Main/Configuration/Template/DRA/Huawei/HuaweiDraDefaultParameters";
import DraNextIndex from "../pages/Main/Configuration/Template/DRA/NextIndex/DraNextIndex";
import TemplateF5eDnsDefault from "../pages/Main/Configuration/Template/EDNS/F5/F5eDnsDefaultParameters";
import TemplateSixbellGlrDefault from "../pages/Main/Configuration/Template/GLR/SIXBELL/SixbellGlrDefaultParameters";
import TemplateEricssonHlrDefault from "../pages/Main/Configuration/Template/HLR/Ericsson/Default/EricssonHlrDefaultParameters";
import TemplateEricssonHlrList from "../pages/Main/Configuration/Template/HLR/Ericsson/List/TemplateEricssonHLRList";
import TemplateEricssonHlrRegister from "../pages/Main/Configuration/Template/HLR/Ericsson/Register/TemplateEricssonHLRRegister";
import TemplateEricssonHssDefault from "../pages/Main/Configuration/Template/HSS/Ericsson/EricssonHssDefaultParameters";
import TemplateHuaweiMmeDefault from "../pages/Main/Configuration/Template/MME/Huawei/HuaweiMmeDefaultParameters";
import TemplateEricssonMscDefault from "../pages/Main/Configuration/Template/MSC/Ericsson/EricssonMscDefaultParameters";
import TemplateHuaweiPtsDefault from "../pages/Main/Configuration/Template/PTS/HUAWEI/HuaweiPtsDefaultParameters";
import TemplateSixbellSorDefault from "../pages/Main/Configuration/Template/SOR/Sixbell/SixbellSorDefaultParameters";
import TemplateHuaweiUgwDefaultParameters from "../pages/Main/Configuration/Template/UGW/HUAWEI/HuaweiUgwDefaultParameters";
import TemplateHuaweiUgwList from "../pages/Main/Configuration/Template/UGW/HUAWEI/TemplateHuaweiUgwList";

import IgnoredCases from "../pages/Main/Configuration/IgnoredCases/IgnoredCasesList";

//IR21
import Ir21Template from "../pages/Main/Configuration/Tadig/Ir21Template/Ir21Template";
import Ir21AutomaticSetup from "../pages/Main/Ir21/Automatic/Ir21AutomaticSetup";
import Ir21Search from "../pages/Main/Ir21/Search/Ir21Search";
import UploadIr21 from "../pages/Main/Ir21/Upload/Upload";

//REPORT
import ReportCountryView from "../pages/Main/Report/CountryView/ReportCountryView";
import ReportErrorDetail from "../pages/Main/Report/Error/Detail";
import ReportErrorSummary from "../pages/Main/Report/Error/Summary";
import ReportNetworkElementViewDetail from "../pages/Main/Report/NetworkElementView/Detail/ReportNetworkElementViewDetail";
import ReportNetworkElementView from "../pages/Main/Report/NetworkElementView/Summary/ReportNetworkElementView";
import ReportTadigDetailView from "../pages/Main/Report/TadigView/Detail/ReportTadigDetailView";
import ReportTadigSummaryView from "../pages/Main/Report/TadigView/Summary/ReportTadigSummaryView";

//VERTIS
import VertisApplicationLog from "../pages/Main/Vertis/ApplicationLog/VertisApplicationLog";
import VertisFazPraMim from "../pages/Main/Vertis/FazPraMim/VertisFazPraMim";
import VertisPostgresAnalyzer from "../pages/Main/Vertis/PostgresAnalyzer/VertisPostgresAnalyzer";
import VertisTrialSettings from "../pages/Main/Vertis/TrialSettings/VertisTrialSettings";
import VertisUsers from "../pages/Main/Vertis/Users/VertisUserList";

//UPLOAD LOG
import LogUpload from "../pages/Main/Logs/LogUpload";

// Error Components
import PageNotFound from "../pages/Common/Error/PageNotFound";
import Loading from "../pages/Common/Loading/Loading";

function Routes() {
  const { loading } = useContext(Context);
  const history = useHistory();

  useEffect(() => {
    history.listen((location, action) => {
      if (location.state) {
        const { message, messageType } = location.state;

        if (message && messageType) {
          if (
            ["info", "success", "warning", "error", "dark"].includes(
              messageType
            )
          ) {
            toast[messageType](message);
          }
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  }

  // Private Route Modelo :
  // roles não é obrigatório.
  // <PrivateRoute exact path="CAMINHO" roles={[ADMIN, USER]} component={COMPONENT_JS}></PrivateRoute>
  return (
    <Switch>
      <Route exact path="/login" component={Login}></Route>
      <Route
        exact
        path="/forgotpassword"
        component={ForgotPasswordGenerateToken}
      ></Route>
      <Route
        exact
        path="/forgotpassword/success"
        component={ForgotPasswordGenerateTokenSuccess}
      ></Route>
      <Route
        exact
        path="/forgotpassword/change"
        component={ForgotPasswordChangePassword}
      ></Route>

      <PrivateRoute exact path="/" component={Home}></PrivateRoute>

      <PrivateRoute exact path="/alerts" component={AlertList}></PrivateRoute>

      <PrivateRoute
        exact
        path="/users"
        component={UserList}
        roles={[ADMIN, VERTIS]}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/users/reg"
        component={UserRegister}
        roles={[ADMIN, VERTIS]}
      ></PrivateRoute>

      <PrivateRoute exact path="/profile" component={Profile}></PrivateRoute>
      <PrivateRoute
        exact
        path="/profile/password"
        component={ProfileChangePassword}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/release"
        component={ReleaseNotes}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/settings"
        component={SettingsMenu}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/ir21/documents/upload"
        component={UploadIr21}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/ir21/documents/search"
        component={Ir21Search}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/ir21/gsma/automatic-sync-setup"
        component={Ir21AutomaticSetup}
        roles={[ADMIN, VERTIS]}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/ir21/settings/global-title"
        component={GlobalTitleList}
      ></PrivateRoute>
      {/* <PrivateRoute exact path="/ir21/settings/global-title/reg" component={GlobalTitleRegister}></PrivateRoute> */}
      <PrivateRoute
        exact
        path="/ir21/settings/organisations"
        component={OrganisationList}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/ir21/settings/organisations/reg"
        component={OrganisationRegister}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/ir21/settings/tadigs"
        component={TadigList}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/ir21/settings/tadigs/reg"
        component={TadigRegister}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/ir21/settings/tadigs/ir21-template"
        component={Ir21Template}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/ir21/settings/tadigs/association"
        component={TadigAssociation}
        roles={[ADMIN, VERTIS]}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/ir21/settings/tadigs/association/reg"
        component={TadigAssociationRegister}
        roles={[ADMIN, VERTIS]}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/settings/realm"
        component={RealmsList}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/realm/reg"
        component={RealmsRegister}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/settings/network-element"
        component={ElementSummaryList}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/network-element/detail"
        component={ElementList}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/network-element/reg"
        component={ElementRegister}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/settings/partners/hub"
        component={HubList}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/partners/hub/reg"
        component={HubRegister}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/partners/roaming-partners"
        component={RoamingPartnersList}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/partners/roaming-partners/reg"
        component={RoamingPartnersRegister}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/partners/roaming-partners/upload"
        component={RoamingPartnersUploadList}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/settings/report/ignored-cases/:elementType/:vendor/:command"
        component={IgnoredCases}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/report/imsi-change-rules"
        component={ImsiChangeRuleList}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/report/imsi-change-rules/reg"
        component={ImsiChangeRuleRegister}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/settings/report/template/edns/f5/default"
        component={TemplateF5eDnsDefault}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/report/template/hlr/ericsson/default"
        component={TemplateEricssonHlrDefault}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/report/template/hlr/ericsson/template"
        component={TemplateEricssonHlrList}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/report/template/hlr/ericsson/template/reg"
        component={TemplateEricssonHlrRegister}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/report/template/hss/ericsson/default"
        component={TemplateEricssonHssDefault}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/report/template/sgsn-mme/huawei/default"
        component={TemplateHuaweiMmeDefault}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/report/template/dra/huawei/default"
        component={TemplateHuaweiDraDefault}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/report/template/msc/ericsson/default"
        component={TemplateEricssonMscDefault}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/report/template/pts/huawei/default"
        component={TemplateHuaweiPtsDefault}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/report/template/glr/sixbell/default"
        component={TemplateSixbellGlrDefault}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/report/template/sor/sixbell/default"
        component={TemplateSixbellSorDefault}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/report/template/ugw/huawei/default"
        component={TemplateHuaweiUgwDefaultParameters}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/settings/report/template/ugw/huawei/template"
        component={TemplateHuaweiUgwList}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/settings/report/template/dra/next-index"
        component={DraNextIndex}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/report/country-view"
        component={ReportCountryView}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/report/element-view"
        component={ReportNetworkElementView}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/report/element-view/detail/:id"
        component={ReportNetworkElementViewDetail}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/report/tadig-view"
        component={ReportTadigSummaryView}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/report/tadig-view/detail"
        component={ReportTadigDetailView}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/report/error/:elementType/:command"
        component={ReportErrorSummary}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/report/error/:elementType/:command/:field"
        component={ReportErrorDetail}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/upload-log"
        component={LogUpload}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/vertis/log"
        component={VertisApplicationLog}
        roles={[VERTIS]}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/vertis/trial"
        component={VertisTrialSettings}
        roles={[VERTIS]}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/vertis/fazpramim"
        component={VertisFazPraMim}
        roles={[VERTIS]}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/vertis/postgres-analyzer"
        component={VertisPostgresAnalyzer}
        roles={[VERTIS]}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/vertis/users"
        component={VertisUsers}
        roles={[VERTIS]}
      ></PrivateRoute>

      <Route path="*" component={PageNotFound}></Route>
    </Switch>
  );
}

export default Routes;
