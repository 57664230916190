import api from "./api";

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/dra/next-index/default")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteById(id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/dra/next-index/default/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function save(defaultData) {
  return new Promise((resolve, reject) => {
    api({
      method: "post",
      url: `/dra/next-index/default`,
      data: defaultData,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
